<template>
  <div>
      
    <van-nav-bar
    :title="nickname"
    left-arrow
    left-text="返回"
    @click-left="onClickLeft"
    />
        <!-- 导航区域 -->
    <div class="tapCard1" >
        <div class="follow-card">
            <van-tabs   :fixed="false"  class="top-tabCard" inactive-color="#000000"   animated swipeable>
                <van-tab class="taps"  title="Followers">
                    <followers/>
                </van-tab>

                <van-tab  class="taps"  title="Following">

                    <following/>

                </van-tab>

            </van-tabs>
        </div>

    </div>



  </div>
</template>

<script>

import Following from '../components/Follow/Followering.vue'
import Followers from '../components/Follow/Followers.vue'
export default {
    components: { Following, Followers },
    data() {
        return {
        minDate: new Date(1970, 0, 1),
        maxDate: new Date(2022, 0, 1),
        currentDate: "",
        nickname: this.$store.getters.getUser.nickname,
        value: '',
        avatarUrl:""
        }
    },
    methods:{

        onClickLeft() {
        this.$router.push('/Main')
        },

    },

}
</script>

<style>
/* 导航栏 */
.follow-card {
    position: relative;
    
    border-radius: 2px;
}

.follow-card1  div{
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    text-decoration:none;
   
}

.tapCard1 {
    /* position: absolute; */
    height: 100%;
    width: 100%;
    
}


.van-tabs__nav--line {
    margin-right:auto ;
    margin-left:auto ;
    width: 58% ;
    box-sizing: content-box;
    height: 100%;
    padding-bottom: 15px;
}

.van-tab{
 font-size: 16px;
}




</style>