<template>
  <div>
    <van-cell-group >
        <van-cell  class="note" v-show="!myFollowers.length"> 
            <h2>你还没有关注其他人，快去关注吧！</h2>
        </van-cell>
        <van-cell  v-for="(p,index) in myFollowers" :key="index">
            <!-- 用户头像和id -->
            <div class="follow-header" @click="goProfile(index)" >
                <div class="follow-head">
                    <div>
                        <img :src="p.avatar" alt="">
                    </div>
                </div>

                <div class="follow-name">
                    <div >{{p.nickname}}</div>
                </div> 

                <div class="follow1" >
                    <div>
                        <van-button @click.stop="Follow(p,index)" icon="plus" type="info" size="small" round v-show="!isFollowings[index]">关注</van-button>
                        <van-button @click.stop="unFollow(p,index)" plain type="info"  size="small" round v-show="isFollowings[index]" >已关注</van-button>
                    </div>
                </div>
                
            </div>
        </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import {Toast} from 'vant'
    export default {

        data() {
            return {
                myFollowers: [],
                isFollowings : []
            }
        },

        methods:{

            goProfile(index){
                this.$router.push({
                    name:"personDetail",
                    params:{
                        userId: this.myFollowers[index].id
                    }
                })
            },

            unFollow(p,index){
             Dialog.confirm({
                    confirmButtonColor: "#0099FF",
                    cancelButtonColor: "#0099FF",
                    message: '是否取消关注' 
                }).then(()=>{
                    
                    this.isFollowings[index] = !this.isFollowings[index]
                    this.isFollowings.reverse()
                    this.isFollowings.reverse()

                    this.$axios.post("/follow/cancelFollow",{
                        userId: p.id,
                        followerId: this.$store.getters.getUser.id
                    }).then(
                        res=>{
                            //减少被关注用户的被关注人数
                            this.cutFollowerCount(p)
                            //减少用户的关注人数
                            this.cutFollowingCount()
                        }
                    )
                }).catch(()=>{
                    
                })
            
            },

            cutFollowerCount(p){
                this.$axios.post("/user/cutFollower/"+p.id)
            },

            cutFollowingCount(){
                this.$axios.post("/user/cutFollowing/"+this.$store.getters.getUser.id).then(
                    res=>{
                        this.$store.commit("SET_USERINFO", res.data.data)
                    }
                )
            },

            Follow(p,index){
                this.isFollowings[index] = !this.isFollowings[index]
                this.isFollowings.reverse()
                this.isFollowings.reverse()
                this.$axios.post("/follow/addFollow",{
                    userId: p.id,
                    followerId: this.$store.getters.getUser.id
                },{    
                    headers: {"Authorization":localStorage.getItem("token")}
                }).then(
                    res=>{
                        //添加被关注用户的被关注人数
                        this.addFollowerCount(p)
                        //添加用户的关注人数
                        this.addFollowingCount()
                    }
                )
            },

            addFollowerCount(p){
                this.$axios.post("/user/addFollower/"+p.id)
            },

            addFollowingCount(){
                this.$axios.post("/user/addFollowing/"+this.$store.getters.getUser.id).then(
                    res=>{
                        this.$store.commit("SET_USERINFO", res.data.data)
                    }
                )
            },
            
            personalFollows(id,list1){
                this.$axios.get("/follow/following/"+id).then(
                    res=>{
                        let followings = res.data.data
                        let list2 = []
                        
                        followings.forEach(element => {
                            list2.push(element.userId)
                        });
                        for (let i = 0; i < list1.length; i++) {
                            const element = list1[i];
                            this.isFollowings.push(list2.includes(element))
                            
                        }

                    }
                )
            },



            getFollow(id){
                this.$axios.get("/follow/following/"+id).then(
                    res=>{
                        let followings = res.data.data
                        let list1 = []
                        followings.forEach(element => {
                            list1.push(element.userId)
                        });
                        // console.log(list1)
                        this.Followers(list1)
                        this.personalFollows(id,list1)
                    }
                )
            },


            Followers(list){
                if(list.length != 0){
                    this.$axios.get("/user/followers/"+list).then(
                        res=>{
                            this.myFollowers = res.data.data
                            this.myFollowers.reverse()
                        }
                    )
                }

            }
        },


        watch:{
            isFollowings(){
                // console.log(this.isFollowings)
                
            }
        },

        mounted(){    
            var userId = this.$store.getters.getUser.id
            this.getFollow(userId)
        }
    }
</script>

<style>
    .follow-header{
        width:100%;
        display: flex;
        height: 55px;
        
        
    }


    .van-tab--active {
        color: #247bee;
        
    }

    .van-tabs--line .van-tabs__wrap {
        height: 50px;
    }

    .van-tabs__line {
        background-color: #247bee;
    }

    .follow-head {
        position:absolute;
        width: 50px;
        height: 50px;
        background-color: rgb(68, 138, 138);
        border-radius: 50%;
    }

    .follow-head img {
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
        background-color: white;
    }

    .follow-name {
        margin-left: 65px;
        color:black;
        font-size: 17px;
        margin-top: 10px
    }

    .follow1{
        position:absolute;
        right: 0;
        top: 5px;
        height: 48px;
        margin: auto 0;
    }

    .follow1 button{
        /* margin: 10px; */
        float: right;
        width: 80px;
        height: 35px;
        line-height: 30px;

    }

    .note{ 
        align-items: center;
        text-align: center;
        margin: 10px;
        font-size: 16px;
    }

</style>